import React, { useState, useEffect } from "react";
import '../App.css'
import FameUpload from "../hooks/fameUpload";
import AWS from "aws-sdk";

import { MdUpload } from 'react-icons/md'
import { GiLaurelCrown } from 'react-icons/gi'

function Fame(){

    const [searchTerm, setSearchTerm] = useState("");
    

    const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
    const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
    const REGION = "ap-northeast-2";

    AWS.config.update({
        region: REGION,
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY
    });

    const s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        sslEnabled: true
    });

    var params = {
        Bucket : "hyunu",
    };

    const BucketList = () => {
        const [listFiles, setListFiles] = useState([]);

        useEffect(() => {
            s3.listObjectsV2(params, (err, data) => {
                if (err) {
                    console.log(err, err.stack);
                    alert("서버로부터 데이터를 가져오지 못했습니다.")
                } else {
                    setListFiles(data.Contents);
                }
            });
        }, []);

        return(
            <div className="fameList">
            {listFiles &&
            listFiles.filter((val) => {
                if(searchTerm === ""){
                    return val
                } else if (val.Key.toLowerCase().includes(searchTerm.toLowerCase())){
                    return val
                }
            }).map((val, index) => {
                return(
                    <div className="fameImg" key={index}>
                        <div>
                            <picture>
                                <img src={`https://hyunu.s3.ap-northeast-2.amazonaws.com/${val.Key}`} loading="lazy" alt={val.Key}/>
                            </picture>
                        </div>
                        <div>
                            {val.Key.slice(0, -4)}
                        </div>
                    </div>
                )
            })}
        </div>
        )
    }

    function openUpload(){
        const openForm = document.getElementsByClassName('uploadform');

        openForm[0].style.display = "block"
    }

    return(
        <div className="contents">
            <div className="page">
                <div className="fameTitle">
                    <div className="iconTitle">
                        <GiLaurelCrown />
                        <span>
                            명예의 전당
                        </span>
                    </div>
                    <div className="btn" onClick={openUpload}>
                        <MdUpload />
                        <span>
                            업로드
                        </span>
                    </div>
                    <FameUpload />
                </div>
                <div className="fameAssets">
                    <input onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }} type="text" id="value" placeholder="이미지 검색" className="fameValue"/>
                    <div>
                        <BucketList />
                    </div>
                </div>
                <p>
                    현재 최적화중인 기능입니다. 이미지 파일만 업로드 가능합니다.
                </p>
            </div>
        </div>
    )
}

export default Fame;