import React from "react";
import '../App.css'
import sLogo from "../img/simpleLogo.svg"

function Footer(){
    return(
        <div className="footer">
            <div>
                &#9400; 혀누와 얼간이들. All Rights Reserved.&nbsp;디자인 및 개발 | 이경민(GYEONG)
            </div>
            <div>
                <img src={sLogo} height={"40px"}/>
            </div>
        </div>
    )
}

export default Footer;