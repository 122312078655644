import React from "react";
import { Link } from "react-router-dom";
import { SiDevdotto } from "react-icons/si";
import { TbBeta } from "react-icons/tb"
import { FaHamburger } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri"

import '../App.css'

function Header(){

    const patty = document.getElementsByClassName("patty");
    const pageStyle = document.getElementsByTagName("body");

    function openBurger(){
        patty[0].style.display = "block";
        pageStyle[0].style.overflowY = "hidden";
    }

    function closeBurger(){
        patty[0].style.display = "none";
        pageStyle[0].style.overflowY = "scroll";
    }

    function Reload(){
        this.forceUpdate();
    }


    return(
        <div className="header">
            <Link to="/">
                <div>
                    <img src={process.env.PUBLIC_URL + 'logo.svg'} />
                </div>
                <div>
                    <TbBeta />
                </div>
            </Link>
            <div className="burger">
                <FaHamburger onClick={openBurger}/>
            </div>
            <div className="patty">
                <RiCloseCircleFill
                onClick={closeBurger}
                className="closeBtn"
                color="white"
                />
                <div className="sauce">
                    <Link to="/fame" onClick={Reload}>
                        <div>
                            명예의 전당
                        </div>
                    </Link>
                    <Link to="/words" onClick={Reload}>
                        <div>
                            오늘의 한마디
                        </div>
                    </Link>
                    <a href="https://youtube.com/@officialHyunu" target="_blank">
                        <div>
                            유튜브
                        </div>
                    </a>
                    <a href="https://discord.gg/hyunu" target="_blank">
                        <div>
                            디스코드
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Header;