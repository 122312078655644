import React, { useEffect, useState } from "react";
import axios from "axios"

function Words(){

    const [comment, setComment] = useState([]);
    const getData = async () => {
        const url = "http://192.168.1.3:3306/site/comments";
        axios
            .get(url)
            .then((res) => {
                setComment(res.data);
                console.log(comment);
            })
            .catch((error) => {
                console.log(error,"에러")
            });

    }

    return(
        <div className="page">
            <div>
                <div>
                    <input type="text" id="word" placeholder="오늘의 한마디를 적어보세요." />
                </div>
                <div>
                    한마디들
                </div>
            </div>
        </div>
    )
}

export default Words