import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import '../App.css';
import axios from 'axios';

import { SiYoutube, SiWindows11 } from "react-icons/si";
import Youtube from 'react-youtube';

function ServerWidget(){
    const [serverStatus, setServerStatus] = useState([]);

    const location = useLocation();

    useEffect(() => {
        async function fetchData(){
            const res = await fetch('https://api.mcsrvstat.us/2/happysub.kro.kr')
            const data = await res.json();
            setServerStatus(data)
        }
        setInterval(() => fetchData(), 3000);
    }, []);

    return(
        <div className="contents">
            <div className="mcevent">
                <img src={process.env.PUBLIC_URL + 'minecraft.svg'} width={"70px"}/><div>마인크래프트 이벤트</div>
            </div>
            <div className="mcstatus">
                <div className="mctitle">
                    현현의 숲 이벤트
                </div>
                <div className="mcinfo">
                    <div className="mcarticle">
                        <div className="mcbutton">
                            <p>{serverStatus.online?`온라인 상태입니다!`:'오프라인 상태입니다.'}</p>
                        </div>
                        <div className="mcbutton">
                            <p>{serverStatus.online?`접속자 수 : ${serverStatus.players.online}명`:'접속자 수 : -'}</p>
                        </div>
                    </div>
                    <hr className="mcdevide"/>
                    <div className="mcbutton">
                        <p><SiWindows11 /></p>
                        <p><a href="https://github.com/gyeongent/HyunHyunLauncher/releases/download/v2.1.0/hyunhyunForest-setup-2.1.0.exe" target="_blank">런처 다운로드</a></p>
                    </div>
                </div>
            </div>
        </div> 
    )
}

function YoutubeWidget(){

    const [recent, setRecent] = useState([]);

    useEffect(() => {
        axios.get("https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCS5q6zrKC_KDeOEP4xqRj4Q&maxResults=1&order=date&type=video&key=AIzaSyDQZthlBhgHXQx75r9MmuIkyxWcJaQp0WU"
        ).then((res) => {
            setRecent(res.data.items);
        })
        .catch(() => {})
    }, [])

    return(
        <div className="contents youtube">
            <div>
                <span><SiYoutube />최신 동영상</span>
                <Youtube
                    videoId="nR7ov1V6o34"
                    opts={{
                        height: '390',
                        width: '100%',
                        playerVars: {
                            autoplay: 0
                        }
                    }}
                />
            </div>
            <div>
                <span><SiYoutube />인기 동영상</span>
                <Youtube
                videoId="GjGJf9ImywU"
                opts={{
                    height: '390',
                    width: '100%',
                    playerVars: {
                        autoplay: 0
                    }
                }}
            />
            </div>
        </div>
    )
}

function Page(){
    return(
        <div className="page">
            <YoutubeWidget />
            <ServerWidget />
        </div>
    )
}

export default Page;