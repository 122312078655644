import React, {useState} from "react";
import AWS from 'aws-sdk';
import { Row, Col, Button, Input, Alert } from 'reactstrap'
import { RiCloseCircleFill } from "react-icons/ri"
import '../App.css';

function FameUpload(){
    function closeForm(){
        const openForm = document.getElementsByClassName('uploadform');

        openForm[0].style.display = "none"
    }

    const [progress , setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showAlert, setShowAlert] = useState(false);

    const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
    const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
    const REGION = "ap-northeast-2";
    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;

    AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY
    });

    const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
    });

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        const fileExt = file.name.split('.').pop();
        if(file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif' && file.type !== 'image/webp'){
            alert('이미지 파일만 업로드 가능합니다.');
            return;
        }
        setProgress(0);
        setSelectedFile(e.target.files[0]);
    }

    const uploadFile = (file) => {
        const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: file.name
        };

        myBucket.putObject(params)
        .on('httpUploadProgress', (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100))
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setSelectedFile(null);
          }, 3000)
        })
        .send((err) => {
          if (err) console.log(err)
        })
    }

    return(
        <div className="uploadform">
            <div>
                <Row>
                    <Col>
                        { showAlert?
                        <Alert color="primary">업로드 진행률 : {progress}%</Alert>
                        : 
                        <Alert color="primary">파일을 선택해 주세요.</Alert> 
                        }
                    </Col>
                </Row>
            </div>
            <div>
                <input type="file" onChange={handleFileInput} />
                {selectedFile?(
                <Button color="primary" onClick={() => uploadFile(selectedFile)}>명예의 전당에 업로드</Button>
                ) : null }
            </div>
            <div>
                <RiCloseCircleFill className="closeBtn" onClick={closeForm}/>
            </div>
        </div>
    )
}

export default FameUpload