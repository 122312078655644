import { Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './componenets/header';
import Page from './componenets/page';
import Fame from './componenets/fame';
import Words from './componenets/words';
import Footer from './componenets/footer';

function App() {
  return (
    <div className="App">
      <Header />
        <Routes>
          <Route path="/" element={<Page />} />
          <Route path="/fame" element={<Fame />} />
          <Route path="/words" element={<Words />} />
        </Routes>
      <Footer />
    </div>
  );
}

export default App;